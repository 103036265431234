// Export of mariana nord theme that works with prism
export default {
  "plain": {
    "color": "#d8dee9",
    "backgroundColor": "#363d46"
  },
  "styles": [
    {
      "types": [
        "comment",
        "punctuation"
      ],
      "style": {
        "color": "rgb(167, 173, 186)"
      }
    },
    {
      "types": [
        "string",
        "inserted"
      ],
      "style": {
        "color": "rgb(153, 199, 148)"
      }
    },
    {
      "types": [
        "number",
        "changed"
      ],
      "style": {
        "color": "rgb(249, 174, 88)"
      }
    },
    {
      "types": [
        "builtin"
      ],
      "style": {
        "color": "rgb(236, 95, 103)",
        "fontStyle": "italic"
      }
    },
    {
      "types": [
        "char",
        "constant",
        "keyword",
        "attr-name"
      ],
      "style": {
        "color": "rgb(197, 148, 197)"
      }
    },
    {
      "types": [
        "variable",
        "tag",
        "deleted"
      ],
      "style": {
        "color": "rgb(236, 95, 103)"
      }
    },
    {
      "types": [
        "operator"
      ],
      "style": {
        "color": "rgb(249, 123, 88)"
      }
    },
    {
      "types": [
        "function"
      ],
      "style": {
        "color": "rgb(95, 179, 179)"
      }
    }
  ]
};